.uspsWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-neutral-300);
  gap: var(--spacing-3);
  padding: var(--spacing-5);
  margin-top: var(--spacing-6);

  @media (--tablet) {
    width: 720px;
    margin-top: 0;
    width: 100%;
  }

  @media (--tablet-l) {
    width: 100%;
  }

  & .title {
    line-height: normal;

    & span {
      font-weight: var(--font-weight-regular);
    }
  }

  & .promoText {
    & p {
      color: var(--color-primary-500);
      margin: 0;
    }
  }

  & .link {
    color: var(--color-neutral-700);
  }
  & .linkModal {
    color: var(--color-neutral-700);
    cursor: pointer;
  }

  & .usps {
    display: flex;
    flex-direction: column;
    flex: unset;
    margin: 0;
    padding: 0;

    @media (--tablet) and (max-width: 1023px) {
      flex-direction: row;
      flex-wrap: wrap;

      & .uspItem {
        flex: 1 1 40%;
      }
    }
  }

  & .uspItem {
    display: flex;
    gap: var(--spacing-3);
    padding: var(--spacing-1) 0;
    align-items: center;
  }

  & .uspIcon {
    position: relative;
    aspect-ratio: 1/1;
    height: 20px;
  }
}

.uspsPadding {
  @media (--tablet-l) {
    padding: var(--spacing-5) 0;
  }
}

.modalContainer {
  @media (--tablet-l) {
    height: 635px;
    width: 800px;
  }
}

.modalBody {
  overflow: auto;
}

.hr {
  width: 100%;
  border: 0;
  border-top: 1px solid var(--color-neutral-300);
}
